import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Estate } from '../../models/estate.model';
import { plainToClass } from 'class-transformer';
import { map } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class EstatesService {
	constructor(private http: HttpClient) {}

	getEstates(type?:number):Observable<Estate[]> {
		let url = '/api/estates';
		if (type) {
			url += '?type=' + type;
		}
		return this.http.get<Estate[]>(url).pipe(
			map((data) => {
				return plainToClass(Estate, data);
			})
		);
	}
	
	getEstate(id: number):Observable<Estate> {
		return this.http.get<Estate>('/api/estates/' + id).pipe(
			map((data) => {
				return plainToClass(Estate, data);
			})
		);
	}

	updateEstate(id:number, data:any) {
		return this.http.put('/api/estates/' + id, data);
	}
	
	createEstate(data:any) {
		return this.http.post('/api/estates', data);
	}
	
	deleteEstate(id:number) {
		return this.http.delete('/api/estates/' + id);
	}
}
