import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { plainToClass } from 'class-transformer';
import { map } from 'rxjs/operators';
import { User } from '../../models/user.model';

@Injectable({
	providedIn: 'root'
})
export class UsersService {
	constructor(private http: HttpClient) {}

	getUsers(role?:string):Observable<User[]> {
		let url = '/api/users';
		if (role) {
			url += '?role=' + role;
		}

		return this.http.get<User[]>(url).pipe(
			map((data) => {
				return plainToClass(User, data);
			})
		);
	}

	getUser(id:number):Observable<User> {
		return this.http.get<User>('/api/users/' + id).pipe(
			map((data) => {
				return plainToClass(User, data);
			})
		);
	}

	updateUser(id:number, data:any) {
		return this.http.put('/api/users/' + id, data);
	}
	
	createUser(data:any) {
		return this.http.post('/api/user', data);
	}

	deleteUser(id:number) {
		return this.http.delete('/api/users/' + id);
	}
}
