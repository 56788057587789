import { Estate } from './estate.model';
import { User } from './user.model';
import { Document } from './document.model';

export class Contract {
    id:number;
    estate:Estate;
    tenant:User;
    documents:Document;
    income_value:number;
    intial_date:Date;
    expiration_date:Date;
}