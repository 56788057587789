import { User } from './user.model';
import { Contract } from './contract.model';

export class Estate {
    id:number;
    title:string;
    address: {
        street:string,
        number:number,
        zip:string,
        latitude:number,
        longitude:number,
    };
    owner:User;
    type: {
        id:number,
        name:string
    };
    parent_id:number;
    contract:Contract

    get addressAsString() {
        return (this.address.number ? this.address.number + ' ' : '')
        +  (this.address.street ? this.address.street + ' ' : '') 
        + (this.address.zip ? '(' + this.address.zip + ')' : '');
    }
}