import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Cashflow } from '../../models/cashflow.model';
import { plainToClass } from 'class-transformer';
import { map } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class CashflowsService {
	constructor(private http: HttpClient) {}

	getCashflows(contract = null, year = null):Observable<Cashflow[]> {
		
		let url = '/api/cashflows';

		if (contract || year ) {
			url += '?';
		}

		if (contract) {
			url += 'contract=' + contract +'&';
		}

		if (year) {
			url += 'year=' + year +'&';
		}

		return this.http.get<Cashflow[]>(url).pipe(
			map((data) => {
				return plainToClass(Cashflow, data);
			})
		);
	}
	
	getCashflow(id: number):Observable<Cashflow> {
		return this.http.get<Cashflow>('/api/cashflows/' + id).pipe(
			map((data) => {
				return plainToClass(Cashflow, data);
			})
		);
	}

	getCashflowsResume():Observable<Cashflow[]> {
		return this.http.get<Cashflow[]>('/api/cashflows-resume').pipe(
			map((data) => {
				return plainToClass(Cashflow, data);
			})
		);
	}

	getCashflowsMinYears() {
		return this.http.get('/api/cashflows-minyear');
	}
	
	getCashflowResume(id: number, year: number):Observable<Cashflow[]> {
		
		let url = '/api/cashflows-resume';

		if (id) {
			url += '/' + id;
		}

		if (year) {
			url += '?year=' + year;
		}

		return this.http.get<Cashflow[]>(url).pipe(
			map((data) => {
				return plainToClass(Cashflow, data);
			})
		);
	}

	updateCashflow(id:number, data:any) {
		return this.http.put('/api/cashflows/' + id, data);
	}
	
	createCashflow(data:any) {
		return this.http.post('/api/cashflows', data);
	}
	
	deleteCashflow(id:number) {
		return this.http.delete('/api/cashflows/' + id);
	}
}
