import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Contract } from '../../models/contract.model';
import { plainToClass } from 'class-transformer';
import { map } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class ContractsService {
	constructor(private http: HttpClient) {}

	getContracts(contract = null):Observable<Contract[]> {

		let url = '/api/contracts';
		if (contract) {
			url += '?contract=' + contract;
		}
		
		return this.http.get<Contract[]>(url).pipe(
			map((data) => {
				return plainToClass(Contract, data);
			})
		);
	}
	
	getContract(id: number):Observable<Contract> {
		return this.http.get<Contract>('/api/contracts/' + id).pipe(
			map((data) => {
				return plainToClass(Contract, data);
			})
		);
	}

	updateContract(id:number, data:any) {
		return this.http.put('/api/contracts/' + id, data);
	}
	
	createContract(data:any) {
		return this.http.post('/api/contracts', data);
	}
	
	deleteContract(id:number) {
		return this.http.delete('/api/contracts/' + id);
	}

	cashflowCollect(id:number) {
		let data = {};
		return this.http.post('/api/cashflows/' + id +'/collect', data);
	}
}
