import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Expense } from '../../models/expense.model';
import { plainToClass } from 'class-transformer';
import { map } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class ExpensesService {
	constructor(private http: HttpClient) {}

	getExpenses():Observable<Expense[]> {
		return this.http.get<Expense[]>('/api/expenses').pipe(
			map((data) => {
				return plainToClass(Expense, data);
			})
		);
	}
	
	getExpense(id: number):Observable<Expense> {
		return this.http.get<Expense>('/api/expenses/' + id).pipe(
			map((data) => {
				return plainToClass(Expense, data);
			})
		);
	}

	updateExpense(id:number, data:any) {
		return this.http.put('/api/expenses/' + id, data);
	}
	
	createExpense(data:any) {
		return this.http.post('/api/expenses', data);
	}
	
	deleteExpense(id:number) {
		return this.http.delete('/api/expenses/' + id);
	}
}
