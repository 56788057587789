import { Contract } from './contract.model';

export class Cashflow {
    id:number;
    title:string;
    contract:Contract;
    value:number;
    month:number;
    type:string;
    year:number;
    total:number;
    contract_id:number;
    date: string;
}